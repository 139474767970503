<template>
    <div class="fixed top-0 left-0 z-40 w-full h-full black-blur-3">
        <div @click.stop="$emit('close')" class="fixed top-4 right-2 cursor-pointer iphoneMarginTop" style="z-index: 41;">
            <fa-icon icon="xmark" type="fas" class="w-8 h-8" />
        </div>
        <swiper
            :navigation="true" 
            :modules="modules"
            :initialSlide="initialSlide"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
        >
            <swiper-slide v-for="(item, index) in gallery" class="mySwiper" :virtualIndex="index">
                <img v-if="item.type == 'image'" :src="transformUrl(item.url, ['optimize', imageSize, 'q80'])" class="object-scale-down h-auto w-auto" loading="lazy" style="max-height: 95%; max-width: 95%;">
                <div v-else-if="item.url.includes('cloudflarestream')" class="w-full">
                    <div style="position: relative; padding-top: 56.25%">
                        <iframe
                            :src="fixSrc(item.url)"
                            style="border: none; position: absolute; top: 0; height: 100%; width: 100%"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            allowfullscreen="true"
                        ></iframe>
                    </div>
                </div>
                <video :id="`fsgvid${index}`" v-else-if="item.type == 'video'" :poster="posterUrl(item.url, ['optimize'])" preload="none" :autoplay="autoPlay || index == 0" muted controls loop playsinline class="object-contain gallery-video swiper-lazy" style="max-height: 95%; max-width: 95%;">
                    <source :src="transformUrl(item.url,['video', 'optimize'])" type="video/mp4"/>
                    <source :src="transformUrl(item.url,['video', 'optimize'])" type="video/webm"/>
                    <source :src="transformUrl(item.url,['video', 'optimize'])" type="video/ogg"/>
                    Your browser does not support HTML5 video tags
                </video>
            </swiper-slide>
        </swiper>
        <div class="fixed left-0 flex items-center justify-center w-full z-90 bottom-2">
            <div class="flex items-center justify-center mx-auto">
                <div v-for="(image, index) in gallery" :key="index" class="w-1 h-1 rounded-full" :class="{'bg-white': index != activeSlide, 'bg-orange-500': index == activeSlide}" style="margin-left: 1px; margin-right: 1px;">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

import "swiper/css"
import "swiper/css/navigation"

import { Navigation } from "swiper"

export default {
    props:['gallery', 'initialSlide'],
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
        return {
            modules: [Navigation],
        }
    },
    data() {
        return {
            activeSlide: 0,
            currentScrollTop: 0
        }
    },
    computed: {
        autoPlay() {
            return this?.loggedUser?.settings?.autoplay ?? true
        },
        imageSize() {
            return this.windowWidth > 400 ? 'lg' : 'md'
        }
    },
    watch: {
        initialSlide: {
            immediate: true,
            handler(val) {
                this.activeSlide = val
            }
        },
        keyEscape: {
            handler(val) {
                if (val && this.popupCheck('fullScreenGallery')) {
                    this.setStateData([
                        { keyEscape: false }
                    ])

                    this.$emit('close')
                }
            }
        }
    },
    mounted() {
        this.popupIn('fullScreenGallery')

        this.setStateData([
            { keyEscape: false }
        ])

        if (this.popups.length == 1) {
            this.currentScrollTop = this.scrollTop
                        
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant'
            })
            
            document.body.classList.remove("overflow-y-scroll")
            document.body.classList.add("overflow-y-hidden")
            document.body.style.marginTop = -(this.currentScrollTop) + "px"
        }
    },
    beforeUnmount() {
        this.popupOut('fullScreenGallery')

        if (this.popups.length == 0) {
            document.body.classList.remove("overflow-y-hidden")
            document.body.classList.add("overflow-y-scroll")
            document.body.style.marginTop = "0px"
            
            window.scrollTo({
                top: this.currentScrollTop,
                left: 0,
                behavior: 'instant'
            })
        }
    },
    methods: {
        fixSrc(inSrc) {
            return inSrc.replace('/watch', '/iframe')
        },
        onSwiper() {

        },
        onSlideChange(inSwiper) {
            this.activeSlide = inSwiper.activeIndex
        }
    }
}
</script>

<style>
.swiper {
  width: 100%;
  height: 100%;
  @apply flex items-center justify-center
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

:root {
    --swiper-theme-color: #ffffff;
}

.swiper-button-prev, .swiper-button-next {
    background-color: rgba(55, 65, 81, 0.5);
}
</style>