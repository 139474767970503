<template>
    <div id="newBottomMenu" class="left-0 flex w-full bottom-nav text-white overflow-hidden iphoneMarginBottom" :class="{ 'bottom-0': !isIOSApp, 'bottom-2': isIOSApp, 'fixed': !notFixed, 'h-16': showGlobalIcons || !userHideIcons, 'h-0': !showGlobalIcons && userHideIcons }" style="z-index: 39;">
        <div v-if="isLoggedIn && loggedUser" class="flex justify-between items-center h-16 w-full mx-auto px-1 max-main-width">
            <div class="flex items-center justify-start">
                <div @click="clickHome" class="relative overflow-visible cursor-pointer" :class="{ 'orphan-icon-overlay-box': clickedMenu != 1, 'orphan-icon-white-box': clickedMenu == 1 }">
                    <fa-icon icon="house" type="fas" class="orphan-icon h-5 w-5" />
                </div>
            </div>
            <div class="relative flex items-center justify-center">
                <div @click="clickFeed" class="relative overflow-visible cursor-pointer" :class="{ 'orphan-icon-overlay-box': clickedMenu != 2, 'orphan-icon-white-box': clickedMenu == 2 }">
                    <fa-icon icon="clock" type="fas" class="w-5 h-5" />
                </div>
            </div>
            <div class="relative flex items-center justify-center">
                <div @click="clickNewActivity" class="cursor-pointer" :class="{ 'orphan-icon-overlay-box': clickedMenu != 3, 'orphan-icon-white-box': clickedMenu == 3 }">
                    <fa-icon icon="plus" type="fas" class="w-5 h-5" />
                </div>
            </div>
            <div v-if="streamToken" class="relative flex items-center justify-center">
                <notification-icon />
            </div>
            <div class="flex items-center justify-end">
                <div @click="clickOpenBuildsMenu" class="relative w-10 h-10 rounded-full border-2 border-orange-400 cursor-pointer">
                    <user-avatar :url="loggedUser.avatar" :rounded="true" />
                    <div v-show="clickedMenu == 5" class="absolute w-full h-full top-0 left-0 rounded-full bg-white opacity-50">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NotificationIcon from '@/shared/Notifications/NotificationIcon'

export default {
    props: ['notFixed', 'hashtagChallenges'],
    components: {
        NotificationIcon
    },
    data() {
        return {
            clickedMenu: null,
            showHomeIcon: true
        }
    },
    computed: {
        selectedMenu() {
            if (this.menuSelected == 'shop') {
                return this.menu.home
            }

            return this.menu[this.menuSelected]
        }
    },
    watch: {
        menuSelected: {
            immediate: true,
            handler(val) {
                this.showHomeIcon = false

                this.$nextTick(() => {
                    this.showHomeIcon = true
                })
            }
        }
    },
    methods: {
        clickHome() {
            this.clickedMenu = 1

            setTimeout(() => {
                this.routeTo('/')

                if (!this.serverConnected) {
                    this.pageMessage('warning', 'You are currently not connected to the internet, data might be missing or incomplete')
                }
            }, 100)

            setTimeout(() => {
                if (this.clickedMenu == 1) {
                    this.clickedMenu = null
                }
            }, 500)
        },
        clickFeed() {
            this.clickedMenu = 2

            setTimeout(() => {
                this.routeTo('/feed')

                if (!this.serverConnected) {
                    this.pageMessage('warning', 'You are currently not connected to the internet, data might be missing or incomplete')
                }
            }, 100)

            setTimeout(() => {
                if (this.clickedMenu == 2) {
                    this.clickedMenu = null
                }
            }, 500)
        },
        clickNewActivity() {
            this.clickedMenu = 3

            setTimeout(() => {
                this.appsFlyerEvent(
                    {
                        eventName: 'Click Create A Post',
                        eventValue: {
                        }
                    }
                )

                this.openWriteActivity()
            }, 100)

            setTimeout(() => {
                if (this.clickedMenu == 3) {
                    this.clickedMenu = null
                }
            }, 500)
        },
        clickOpenBuildsMenu() {
            this.clickedMenu = 5

            setTimeout(() => {
                this.openBuildsMenu()
            }, 100)

            setTimeout(() => {
                if (this.clickedMenu == 5) {
                    this.clickedMenu = null
                }
            }, 500)
        }
    }
}
</script>