<template>
    <modal v-if="showOnboardingSubCategoriesModal && communitiesModalData" :hideClose="true" :open="showOnboardingSubCategoriesModal" @close="closeModal" class="text-white">
        <modal-background-image
            :bgImage="defaultHero"
        />
        <div class="absolute top-0 left-0 w-full" :class="{ 'pb-16 pt-8': windowWidth < desktopMaxWidth, 'desktop-modal-box': windowWidth >= desktopMaxWidth }">
            <div :class="{ 'desktop-modal': windowWidth >= desktopMaxWidth }">
                <div class="w-full px-3">
                    <cached-image imageClasses="mx-auto mb-4" imageSrc="https://imagedelivery.net/9ggDkXOXYMqLgfSEfVmLkQ/b771c95d-b063-4dfd-5144-7d408955c700/public" style="width: 120px;" />
                    <h3 class="section-heading mb-3 px-1 text-center text-lg">
                        {{ communitiesModalData.heading }}
                    </h3>
                    <div class="font-bold px-1 text-center">
                        {{ communitiesModalData.sub_heading }}
                    </div>
                </div>
                <div class="mt-6 px-1">
                    <div class="round-tabs-container justify-center">
                        <template v-for="(category, index) in sortedCategories" :key="index">
                            <div v-if="category.parent == 0 || isParentOpen(category)" @click="clickCategory(category)" class="round-tab" :class="{ 'round-tab-off': isNonSelectedParent(category), 'round-tab-on': isSelectedParent(category), 'round-tab-child-off': isNonSelectedChild(category), 'round-tab-child-on': isSelectedChild(category) }">
                                {{ category.label }}
                            </div>
                        </template>
                    </div>
                </div>
                <div class="mt-2">
                    <div v-if="groups" class="flex flex-col w-full">
                        <div v-for="(group, index) in groups" :key="index" class="flex" style="padding-bottom: 1px;">
                            <div v-if="group" @click="clickGroup(group.id)" class="w-full cursor-pointer px-1 py-2" :class="{ 'orange-transparent': isGroupSelected(group.id), 'white-transparent': !isGroupSelected(group.id) }">
                                <div class="leading-5 font-semibold text-center">
                                    {{ group.name }}
                                </div>
                                <div class="leading-5 mt-1 text-xs text-center">
                                    {{ membersCount(group.member_count) }} members
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="groups.length" class="mt-6 sm:flex sm:flex-row-reverse px-1">
                    <div @click="clickContinue()" class="standard-button-base mb-2">
                        Continue
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '@/shared/Modal'
import ModalBackgroundImage from '@/shared/ModalBackgroundImage'

export default {
    components: {
        Modal,
        ModalBackgroundImage
    },
    data() {
        return {
            categoriesData: [],
            groupIds: [],
            groups: [],
            selectedBuildTypes: [],
            selectedSubCategories: []
        }
    },
    computed: {
        sortedCategories() {
            return this.categoriesData.sort((a, b) => a.parent > b.parent ? 1 : (a.parent < b.parent ? 0 : (a.label < b.label ? 1 : 0)))
        }
    },
    watch: {
        buildTypes: {
            immediate: true,
            handler(val) {
                if (this.categoriesData.length == 0) {
                    this.buildCategoriesData()
                }
            }
        }
    },
    mounted() {
        this.setStateData([
            { alreadyShownOnboardingModalsThisSession:  true }
        ])
    },
    methods: {
        buildCategoriesData() {
            if (this.categoriesData.length == 0) {
                Object.values(this.buildTypes).forEach((buildType) => {
                    let myRecord = {
                        parent: 0,
                        label: buildType.label,
                        id: buildType.id
                    }

                    this.categoriesData.push(myRecord)

                    buildType.sub_categories.forEach((subCat) => {
                        let myRecord = {
                            parent: buildType.id,
                            label: subCat,
                            id: null
                        }

                        this.categoriesData.push(myRecord)
                    })
                })
            }
        },
        clickCategory(inCategory) {
            if (inCategory.parent == 0) {
                if (this.selectedBuildTypes.includes(inCategory.id)) {
                    let myBuildTypes = this.objCopy(this.selectedBuildTypes)
                    myBuildTypes = myBuildTypes.filter((buildType) => buildType != inCategory.id)
                    this.selectedBuildTypes = this.objCopy(myBuildTypes)
                }
                else {
                    this.selectedBuildTypes.push(inCategory.id)
                }
            }
            else {
                if (this.selectedSubCategories.includes(inCategory.label)) {
                    let mySubCats = this.objCopy(this.selectedSubCategories)
                    mySubCats = mySubCats.filter((subCat) => subCat != inCategory.label)
                    this.selectedSubCategories = this.objCopy(mySubCats)
                }
                else {
                    this.selectedSubCategories.push(inCategory.label)
                }
            }

            this.searchGroups()
        },
        clickContinue() {
            this.appsFlyerEvent(
                {
                    eventName: 'Onboarding Communities Continue',
                    eventValue: {
                    }
                }
            )

            this.setUserCommunities(this.groupIds)            
            this.closeModal()
        },
        clickGroup(inGroupId) {
            if (this.groupIds.includes(inGroupId)) {
                let myGroupIds = this.objCopy(this.groupIds)
                myGroupIds = myGroupIds.filter((groupId) => groupId != inGroupId)
                this.groupIds = this.objCopy(myGroupIds)
            }
            else {
                this.groupIds.push(inGroupId)
            }
        },  
        closeModal() {
            this.setLoggedIn()

            this.setStateData([
                { showOnboardingSubCategoriesModal: false }
            ])
        },
        membersCount(inCount) {
            return inCount ?? 0
        },
        isGroupSelected(inGroupId) {
            return this.groupIds.includes(inGroupId)
        },
        isNonSelectedChild(inCategory) {
            return inCategory.parent != 0 && !this.selectedSubCategories.includes(inCategory.label)
        },
        isNonSelectedParent(inCategory) {
            return inCategory.parent == 0 && !this.selectedBuildTypes.includes(inCategory.id)
        },
        isParentOpen(inSubCat) {
            return this.selectedBuildTypes.includes(inSubCat.parent)
        },
        isSelectedChild(inCategory) {
            return inCategory.parent != 0 && this.selectedSubCategories.includes(inCategory.label)
        },
        isSelectedParent(inCategory) {
            return inCategory.parent == 0 && this.selectedBuildTypes.includes(inCategory.id)
        },
        searchGroups() {
            if (this.selectedBuildTypes.length && this.serverConnected) {
                buildzAxios?.get(this.getRoute('communitySearch'), {
                    params: {
                        buildType: this.selectedBuildTypes,
                        subCategories: this.selectedSubCategories
                    }
                })
                .then((response) => {
                    if (response.status == 200 && response.data.groups) {
                        this.groups = this.objCopy(response.data.groups)
                    }
                    else {
                        // this.pageMessage('error', 'Error retrieving data')
                    }
                })
                .catch((error) => {
                    console.log(error)
                    // this.pageMessage('error', 'Error retrieving data')
                })
            }
        }
    }
}
</script>