<template>
    <div class="w-full flex justify-center">
        <img :class="{'w-48': isDesktopWidth, 'w-24': !isDesktopWidth }" src="https://imagedelivery.net/9ggDkXOXYMqLgfSEfVmLkQ/44cfb936-9194-4380-779b-419dd1174900/public" />
    </div>
</template>

<script>
export default {

}
</script>