import axios from "axios"

const mediaMixins = {
    computed: {
        allowedImageTypes() {
            return ['image/png', 'image/gif', 'image/jpeg', 'image/webp', 'image/tiff']
        },
        allowedImageTypesString() {
            return this.allowedImageTypes.join()
        },
        allowedMediaTypes() {
            return ['image/png', 'image/gif', 'image/jpeg', 'image/webp', 'image/tiff', 'video/mov', 'video/mp4', 'video/avi', 'video/mpeg', 'video/mkv', 'video/ogv', 'video/webm', 'video/wmv', 'video/3gpp', 'video/3gpp2', 'video/x-m4v', 'video/quicktime']
        },
        allowedMediaTypesString() {
            return this.allowedMediaTypes.join()
        }
    },
    methods: {
        async buildOfflinePostGallery(inGallery) {
            return new Promise(async (resolve, reject) => {
                let myOfflineMedia = null

                if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                    myOfflineMedia = await this.getStorage('offlineMediaFiles')
                    
                    if (myOfflineMedia?.value) {
                        myOfflineMedia = JSON.parse(myOfflineMedia.value)
                    }
                    else {
                        myOfflineMedia = []
                    }
                }
                else {
                    myOfflineMedia = this.offlineMediaFiles

                    if (myOfflineMedia) {
                        myOfflineMedia = JSON.parse(myOfflineMedia)
                    }
                    else {
                        myOfflineMedia = []
                    }
                }
                
                let myPostGallery = []
                let myQueueIds = []   
                

                for (const galleryItem of inGallery) {
                    let myOfflineFile = myOfflineMedia.filter((mediaFile) => mediaFile.url == galleryItem.url)

                    if (myOfflineFile.length) {
                        myOfflineFile = myOfflineFile[0]
                        let myQueueId = await this.addToQueue('updateSingleImage', myOfflineFile)
                        myQueueIds.push(myQueueId)

                        myPostGallery.push({
                            queueId: myQueueId
                        })
                    }
                    else {
                        myPostGallery.push(galleryItem)
                    }
                }

                resolve({
                    gallery: myPostGallery,
                    queueIds: myQueueIds
                })
            })
        },
        async buildOfflineTempGallery(inGallery, inTempNameString) {
            let loopPromise = new Promise(async (resolve, reject) => {
                let myTempGallery = []
                
                inGallery.forEach(async (galleryItem, index) => {
                    let myOfflineMedia = null

                    if ('File' in window && galleryItem instanceof File) {
                        let readerPromise = new Promise((resolve, reject) => {
                            const reader = new FileReader()
                            reader.readAsDataURL(galleryItem)
                            reader.onload = () => resolve(reader.result)
                            reader.onerror = error => reject(error)
                        })

                        let myFile = null

                        await readerPromise
                            .then((result) => {
                                myFile = result
                            })
                            .catch((e) => {

                            })

                        if (myFile) {
                            this.setStorage(`img_${inTempNameString}_${index}`, myFile)
                            let myUrl = `${inTempNameString}_${index}`

                            myTempGallery.push(
                                {
                                    url: myUrl,
                                    type: galleryItem.type.split('/')[0],
                                    identifier: galleryItem?.identifier ?? null
                                }
                            )

                            if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                                myOfflineMedia = await this.getStorage('offlineMediaFiles')
                                
                                if (myOfflineMedia?.value) {
                                    myOfflineMedia = JSON.parse(myOfflineMedia.value)
                                }
                                else {
                                    myOfflineMedia = []
                                }
                            }
                            else {
                                myOfflineMedia = this.offlineMediaFiles

                                console.log('offline', JSON.parse(myOfflineMedia))

                                if (myOfflineMedia) {
                                    myOfflineMedia = JSON.parse(myOfflineMedia)
                                }
                                else {
                                    myOfflineMedia = []
                                }
                            }

                            myOfflineMedia = myOfflineMedia.filter((mediaItem) => mediaItem.url != myUrl)

                            myOfflineMedia.push({
                                url: myUrl,
                                file: {
                                    imageName: galleryItem.name,
                                    imageType: galleryItem.type,
                                    image: myFile
                                },
                                identifier: galleryItem?.identifier ?? null
                            })

                            if (this.deviceInfo && this.deviceInfo.platform != 'web') {
                                this.setStorage('offlineMediaFiles', JSON.stringify(myOfflineMedia))
                            }
                            else {
                                this.setStateData([
                                    { offlineMediaFiles: JSON.stringify(myOfflineMedia) }
                                ])
                            }
                        }
                    }
                    else {
                        myTempGallery.push(galleryItem)
                    }

                    if (index === inGallery.length - 1) {
                        resolve({
                            gallery: myTempGallery, 
                            offlineMedia: myOfflineMedia
                        })
                    }
                })
            })

            let myReturn = null

            await loopPromise
                .then((result) => {
                    myReturn = result
                })
                .catch((e) => {

                })

            // if (this.deviceInfo && this.deviceInfo.platform != 'web') {
            //     this.setStorage('offlineMediaFiles', JSON.stringify(myReturn.offlineMedia))
            // }
            // else {
            //     this.setStateData([
            //         { offlineMediaFiles: JSON.stringify(myReturn.offlineMedia) }
            //     ])
            // }

            return myReturn.gallery
        },
        mediaProgressStart() {
            if(!this.saved && !this.saving){
                this.setStateData([
                    { mediaProgressTotal: this.mediaProgressTotal + 1 }
                ])
            }
            window.mediaInProgress = true
            
            this.setStateData([
                { mediaInProgress: true }
            ])
        },
        mediaProgressDone() {
            this.setStateData([
                { mediaProgress: this.mediaProgress + 1 }
            ])

            setTimeout(() => {
                if(this.mediaProgress >= this.mediaProgressTotal){
                    this.setStateData([
                        { mediaProgressTotal: 0 },
                        { mediaProgress: 0 }
                    ])
                }
            }, 5000)

            if (this.mediaProgress >= this.mediaProgressTotal){
                window.mediaInProgress = false
                
                this.setStateData([
                    { mediaInProgress: false }
                ])
            }
        },
        uploadImage(inFile, inQueueId, inIdentifier) {
            let bodyFormData = new FormData()
            let myFileType = inFile.type.includes('image') ? 'image' : 'video'
            bodyFormData.append('file', inFile)
            bodyFormData.append('type', myFileType)
            
            buildzAxios?.post(this.getRoute('cloudFlare'), bodyFormData)
                .then((response) => { 
                    if (response.status == 200) {
                        let myImages = this.uploadImages
                        let strippedFile = null
                        
                        if (myFileType == 'image') {
                            strippedFile = {
                                'type': myFileType,
                                'url': response.data.image.result.variants[0],
                                'filename': response.data.image.result.filename,
                                'id': response.data.image.result.id,
                                'identifier': inIdentifier
                            }
                        }
                        else {
                            strippedFile = {
                                'type': myFileType,
                                'url': response.data.image.result.preview,
                                'id': response.data.image.result.id,
                                'thumbnail': response.data.image.result.thumbnail
                            }
                        }
                            
                        myImages.push({
                            queueId: inQueueId,
                            image: strippedFile
                        })

                        this.replaceStateDataArray([
                            { uploadImages: this.objCopy(myImages) }
                        ])

                        this.stopQueue(inQueueId, true)
                    }
                })
        }
    }
}

export default mediaMixins