<template>
    <div v-if="notification && !latestActivity.verb.includes('_checklist') && !amIBlocking(actor.id)" class="flex items-start px-3 py-4">
        <div class="w-8 h-8 rounded-full border-2 border-orange-500" style="min-width: 2rem; min-height: 2rem;">
            <div v-if="isSystemPost">
                <img class="object-cover w-full h-full rounded-full" src="https://imagedelivery.net/9ggDkXOXYMqLgfSEfVmLkQ/5ce7174b-dd37-43ea-d2a7-6f09276f4f00/public" loading="lazy" />
            </div>
            <router-link v-if="!isSystemPost" :to="`/garage/${actor.handle}`" @click.stop="closeNotifications()">
                <user-avatar :url="actor ? actor.profileImage : ''" :rounded="true" />
            </router-link>
        </div>
        <div class="w-full ml-2 cursor-pointer" @click.stop="notifyClick()">
            <div class="break-words">
                <router-link @click.stop="closeNotifications()" class="font-bold" v-if="showActor" :to="`/garage/${actor.handle}`">
                    {{ actor ? actor.name : 'Unknown' }}
                </router-link>
                {{ text }}
            </div>
            <div class="text-xs">
                {{ activityAge }}
            </div>
            <div v-if="latestActivity.verb == 'follow' && latestActivity.target" class="flex justify-end mt-2">
                <follow-button :isFollowing="amIFollowing(latestActivity.target)" :owner="{id: latestActivity.target}" :showText="true" buttonClasses="inline-flex" :small="false" />
            </div>
            <div v-if="latestActivity.verb == 'collaboration_invite' && !collaborationApproved && !collaborationDenied" class="flex items-center mt-2">
                <div @click.stop="approveCollaboration(latestActivity.meta.collaboration_id, latestActivity.meta.build.data.id, true)" class="px-2 py-1 text-sm font-medium text-white bg-green-400 rounded-md cursor-pointer">
                    Yes
                </div>
                <div @click.stop="approveCollaboration(latestActivity.meta.collaboration_id, latestActivity.meta.build.data.id, false)" class="px-2 py-1 ml-2 text-sm font-medium text-white bg-red-400 rounded-md cursor-pointer">
                    No
                </div>
            </div>
            <div v-if="latestActivity.verb == 'collaboration_invite' && collaborationApproved" class="flex items-center mt-2">
                <fa-icon icon="check-circle" type="fas" class="w-4 h-4 mr-1 text-green-400" />
                <span>
                    Approved
                </span>
            </div>
            <div v-if="latestActivity.verb == 'collaboration_invite' && collaborationDenied" class="flex items-center mt-2">
                <fa-icon icon="check-circle" type="fas" class="w-4 h-4 mr-1 text-red-400" />
                <span>
                    Denied
                </span>
            </div>

            <div v-if="latestActivity.verb == 'build_transfer' && !transferApproved && !transferDenied" class="flex items-center mt-2">
                <div @click="approveBuildTransfer(latestActivity.meta.transfer_id, latestActivity.meta.build.data.id, true)" class="px-2 py-1 text-sm font-medium text-white bg-green-400 rounded-md cursor-pointer">
                    Yes
                </div>
                <div @click="approveBuildTransfer(latestActivity.meta.transfer_id, latestActivity.meta.build.data.id, false)" class="px-2 py-1 ml-2 text-sm font-medium text-white bg-red-400 rounded-md cursor-pointer">
                    No
                </div>
            </div>
            <div v-if="latestActivity.verb == 'build_transfer' && transferApproved" class="flex items-center mt-2">
                <fa-icon icon="check-circle" type="fas" class="w-4 h-4 mr-1 text-green-400" />
                <span>
                    Approved
                </span>
            </div>
            <div v-if="latestActivity.verb == 'build_transfer' && transferDenied" class="flex items-center mt-2">
                <fa-icon icon="check-circle" type="fas" class="w-4 h-4 mr-1 text-red-400" />
                <span>
                    Denied
                </span>
            </div>
        </div>
        <div v-if="actorGroup" class="flex self-center ml-2">
            <div v-for="(myActor, index) in actorGroup" :key="index" class="w-6 h-6 -ml-3 border border-white rounded-full" style="min-width: 1.5rem; min-height: 1.5rem;">
                <user-avatar :url="myActor.profileImage" :rounded="true" />
            </div>    
        </div>
    </div>
</template>

<script>
import FollowButton from '@/shared/FollowButton'

export default {
    props: {
        notification: null
    },
    components: {
        FollowButton
    },
    data() {
        return {
            avatarError: false,
            avatarGroupError: []
        }
    },
    computed: {
        activities() {
            return this.notification && this.notification.activities && this.notification.activities.length ? this.notification.activities : null
        },
        activityAge() {
            return this.humanizedAge(this.latestActivity.time)
        },
        actor() {
            return this.latestActivity ? this.latestActivity.actor.data : null
        },
        actorCount() {
            if (this.activities.length < 2) {
                return 0
            }

            return this.activities.reduce((actors, activity) => {
                if (!actors.map(actor => actor.id).includes(activity.actor.data.id) && activity.id != this.latestActivity.id && activity.actor.data.id != this.actor.id) {
                    actors.push(activity.actor.data)
                }
                return actors
            }, [])
            .length
        },
        actorGroup() {
            if (this.activities.length < 2) {
                return null
            }

            return this.activities.reduce((actors, activity) => {
                    if (!actors.map(actor => actor.id).includes(activity.actor.data.id) && activity.id != this.latestActivity.id && activity.actor.data.id != this.actor.id) {
                        actors.push(activity.actor.data)
                    }
                    return actors
                }, [])
                .slice(0, 4)
        },
        collaborationApproved() {
            if (!this.loggedUser || !this.loggedUser?.approved_collaboration_ids) {
                return false
            }

            return this.loggedUser?.approved_collaboration_ids.includes(parseInt(this.latestActivity?.meta?.build?.data?.id))
        },
        collaborationDenied() {
            if (!this.loggedUser || !this.loggedUser?.denied_collaboration_ids) {
                return false
            }

            return this.loggedUser?.denied_collaboration_ids.includes(parseInt(this.latestActivity?.meta?.build?.data?.id))
        },
        isSystemPost() {
            return this.latestActivity.verb == 'complete_checklist' || this.latestActivity.verb == 'completed_checklist' || this.latestActivity.verb == 'founder_inactive' || this.latestActivity.verb == 'build_traffic' || this.latestActivity.verb.includes('founder_warning') || this.latestActivity.verb == 'group_approval' || this.latestActivity.verb == 'badge_awarded' || this.latestActivity.verb == 'build_traffic' || this.latestActivity.verb.includes('founder_warning') || this.latestActivity.verb == 'group_approval' || this.latestActivity.verb == 'user_achievement' || this.latestActivity.verb == 'user_badge' || this.latestActivity.verb == 'hashtag_badge' || this.latestActivity.verb == 'leaderboard_badge' || this.latestActivity.verb == 'member_event_badge'
        },
        latestActivity() {
            return this.activities.length ? this.activities[0] : null
        },
        multiUsers() {
            let headerText = null
            let actorName = this.actor?.name
            let latestActivity = this.latestActivity
            let activityVerb = latestActivity.object.verb
            let countOtherActors = this.actorCount

            switch (latestActivity.verb) {
                case 'chat':
                    headerText = "New chat message."
                    break
                case 'like':
                    headerText = ` and ${countOtherActors} others likes your ${activityVerb}.`
                    break
                case 'activityLike':
                    headerText = ` and ${countOtherActors} others likes your post.`
                    break
                case 'postReactionLike':
                    headerText = ` and ${countOtherActors} others likes your comment.`
                    break
                case 'repost':
                    headerText = ` and ${countOtherActors} others reposted your ${activityVerb}.`
                    break
                case 'follow':
                    headerText = ` and ${countOtherActors} others started following you.`
                    break
                case 'comment':
                    if (latestActivity.meta && latestActivity.meta['reaction_owner'] && parseInt(latestActivity.meta['reaction_owner']) == this.loggedUserId) {
                        headerText = ` and ${countOtherActors} others replied to your comment.`
                    } 
                    else if (latestActivity.meta['activity_owner_label']) {
                        headerText = ` and ${countOtherActors} others replied to a comment on ${latestActivity.meta['activity_owner_label']} post.`
                    } 
                    else {
                        headerText = ` and ${countOtherActors} others replied to a comment.`
                    }

                    break
                case 'reaction':
                    if (latestActivity.meta && latestActivity.meta['activity_owner'] && parseInt(latestActivity.meta['activity_owner']) == this.loggedUserId) {
                        headerText = ` and ${countOtherActors} others commented on your post.`
                    } 
                    else if (latestActivity.meta && latestActivity.meta['users'] && latestActivity.meta['users'].includes(this.loggedUserId)) {
                        headerText = ` and ${countOtherActors} others mentioned you in a post.`
                    } 
                    else if (latestActivity.meta['activity_owner_label']) {
                        headerText = ` and ${countOtherActors} others also commented on ${latestActivity.meta['activity_owner_label']} post.`
                    } 
                    else {
                        headerText = ` and ${countOtherActors} others commented on a post.`
                    }

                    break
                case 'post':
                    if (latestActivity.meta && latestActivity.meta['build_owners'] && latestActivity.meta['build_owners'].includes(this.loggedUserId)) {
                        headerText = ` and ${countOtherActors} others made a post on ${latestActivity?.meta?.build?.data?.handle}.`
                    } 
                    else if (latestActivity.meta && latestActivity.meta['garage_owner'] && parseInt(latestActivity.meta['garage_owner']) == this.loggedUserId) {
                        headerText = ` and ${countOtherActors} others made a post on your garage.`
                    } 
                    else if (latestActivity.meta && latestActivity.meta['event_owner'] && parseInt(latestActivity.meta['event_owner']) == this.loggedUserId) {
                        headerText = ` and ${countOtherActors} others made a post on your event.`
                    } 
                    else {
                        headerText = ` and ${countOtherActors} others mentioned you in a post.`
                    }

                    break
                case 'group_post':
                    headerText = ` and ${countOtherActors} others made a post in community ${latestActivity.meta['group_name']}.`

                    break
                default:
                    
            }

            return headerText
        },
        showActor() {
            return ['collaboration_invite', 'chat_invite', 'group_post', 'group_invite', 'group_request', 'event_invite', 'like', 'activityLike', 'postReactionLike', 'repost', 'follow', 'post', 'comment', 'reaction'].includes(this.latestActivity?.verb)
        },
        singleUser() {
            let headerText = null
            let actorName = this.actor?.name
            let latestActivity = this.latestActivity
            let activityVerb = latestActivity.object.verb

            switch (latestActivity.verb) {
                case 'vendor_shopify_products_import_success':
                    headerText = `Your Shopify product data was successfully imported`
                    break
                case 'vendor_shopify_products_push_fail':
                    headerText = `Your product data push to the marketplace failed`
                    break
                case 'vendor_shopify_products_push_success':
                    headerText = `Your product data was successfully pushed to the marketplace`
                    break
                case 'collection_contract_build_success':
                    headerText = `Build successfully added to NFT collection contract!`
                    break
                case 'member_event_badge':
                    headerText = `You\'ve earned an exclusive BZ Gold badge! "${latestActivity?.meta?.badge_text}"`
                    break
                case 'leaderboard_badge':
                    headerText = `You\'ve earned a new event leaderboard badge! "${latestActivity?.meta?.badge_text}"`
                    break
                case 'hashtag_badge':
                    headerText = `You\'ve earned the "${latestActivity?.meta?.badge_text}" badge!`
                    break
                case 'user_badge':
                    headerText = `You\'ve earned the "${latestActivity?.meta?.badge_text}" badge!`
                    break
                case 'user_achievement':
                    headerText = `You\'ve earned the "${latestActivity?.meta?.badge_text}" badge!`
                    break
                case 'badge_awarded':
                    headerText = `You\'ve unlocked the ${latestActivity?.meta?.badge_text} badge for ${latestActivity?.meta?.build?.data?.handle}!`
                    break
                case 'group_approval':
                    headerText = `You have members awaiting approval in ${latestActivity.meta.group_name}.`
                    break
                case 'collaboration_invite':
                    headerText = ` has invited you to collaborate on their build ${latestActivity?.meta?.build?.data?.handle}.`
                    break
                case 'chat':
                    headerText = "New chat message."
                    break
                case 'group_request':
                    headerText = ` wants to join community ${latestActivity.meta.group_name}.`
                    break
                case 'group_invite':
                    headerText = ` invited you to community ${latestActivity.meta.group_name}.`
                    break
                case 'event_invite':
                    headerText = ` invited you to event ${latestActivity.meta.event_name}.`
                    break
                case 'chat_invite':
                    headerText = ` invited you to chat ${latestActivity.meta.chat_name}.`
                    break
                case 'build_traffic':
                    //headerText = `Your build ${latestActivity?.meta?.build?.data?.handle} has had ${latestActivity.meta.hits} visits in the past ${latestActivity.meta.days}, your profile has had ${latestActivity.meta.garage_hits} visits in the past ${latestActivity.meta.days}. Add more content to keep your viewers engaged.`
                    headerText = `Your build ${latestActivity?.meta?.build?.data?.handle} is getting amazing interest!`
                    break
                case 'founder_warning_one':
                    headerText = `See what's new on BZ!`
                    break
                case 'founder_warning_two':
                    headerText = `See what's new on BZ!`
                    break
                case 'founder_warning_three':
                    headerText = `See what's new on BZ!`
                    break
                case 'founder_inactive':
                    headerText = `See what's new on BZ!`
                    break
                // case 'complete_checklist':
                //     headerText = `Complete your Getting Started checklist and claim your Builds.pro 20% store discount voucher!`
                //     break
                // case 'completed_checklist':
                //     headerText = `Congratulations you completed your Getting Started checklist! Claim your Buildz.pro 20% store discount voucher now!`
                //     break
                case 'transfer_approval':
                    headerText = `${actorName} ${latestActivity.meta.status} transfer of Build ${latestActivity?.meta?.build?.data?.handle}.`
                    break
                case 'build_transfer':
                    headerText = `${actorName} wants to transfer Build ${latestActivity?.meta?.build?.data?.handle} to you.`
                    break
                case 'collaboration_request':
                    headerText = `Add ${actorName} as a builder for ${latestActivity?.meta?.build?.data?.handle}.`
                    break
                case 'like':
                    headerText = ` likes your ${activityVerb}.`
                    break
                case 'activityLike':
                    headerText = ` likes your post.`
                    break
                case 'postReactionLike':
                    headerText = ` likes your comment.`
                    break
                case 'repost':
                    headerText = ` reposted your ${activityVerb}.`
                    break
                case 'follow':
                    headerText = ` started following you.`
                    break
                case 'post':
                    if (latestActivity.meta && latestActivity.meta['build_owners'] && latestActivity.meta['build_owners'].includes(this.loggedUserId)) {
                        headerText = ` made a post on ${latestActivity?.meta?.build?.data?.handle}.`
                    } 
                    else if (latestActivity.meta && latestActivity.meta['garage_owner'] && parseInt(latestActivity.meta['garage_owner']) == this.loggedUserId) {
                        headerText = ` made a post on your garage.`
                    }
                    else if (latestActivity.meta && latestActivity.meta['event_owner'] && parseInt(latestActivity.meta['event_owner']) == this.loggedUserId) {
                        headerText = ` made a post on your event.`
                    } 
                    else {
                        headerText = ` mentioned you in a post.`
                    }
                    
                    break
                case 'comment':
                    if (latestActivity.meta && latestActivity.meta['reaction_owner'] && parseInt(latestActivity.meta['reaction_owner']) == this.loggedUserId) {
                        headerText = ` replied to your comment.`
                    } 
                    else if (latestActivity.meta['activity_owner_label']) {
                        headerText = ` replied to a comment on ${latestActivity.meta['activity_owner_label']} post.`
                    } 
                    else {
                        headerText = ` replied to a comment.`
                    }

                    break
                case 'reaction':
                    if (latestActivity.meta && latestActivity.meta['activity_owner'] && parseInt(latestActivity.meta['activity_owner']) == this.loggedUserId) {
                        headerText = ` commented on your post.`
                    } 
                    else if (latestActivity.meta && latestActivity.meta['users'] && latestActivity.meta['users'].includes(this.loggedUserId)) {
                        headerText = ` mentioned you in a comment.`
                    } 
                    else if (latestActivity.meta['activity_owner_label']) {
                        headerText = ` also commented on ${latestActivity.meta['activity_owner_label']} post.`
                    } 
                    else {
                        headerText = ` commented on a post.`
                    }

                    break
                case 'group_post':
                    headerText = ` made a post in community ${latestActivity.meta['group_name']}.`

                    break
                default:
                    
            }

            return headerText
        },
        text() {
            if (!this.activities.length) {
                return null
            }
            
            return this.actorCount == 0 ? this.singleUser : (this.actorCount == 1 ? this.twoUsers : this.multiUsers)
        },
        transferApproved() {
            if (!this.loggedUser || !this.loggedUser.approved_build_transfers || !this.loggedUser.approved_build_transfers.length) {
                return false
            }

            return this.loggedUser.approved_build_transfers.includes(this.latestActivity.meta.transfer_id)
        },
        transferDenied() {
            if (!this.loggedUser || !this.loggedUser.denied_build_transfers || !this.loggedUser.denied_build_transfers.length) {
                return false
            }

            return this.loggedUser.denied_build_transfers.includes(this.latestActivity.meta.transfer_id)
        },
        twoUsers() {
            let headerText = null
            let actorName = this.actor?.name
            let latestActivity = this.latestActivity
            let activityVerb = latestActivity.object.verb

            switch (latestActivity.verb) {
                case 'chat':
                    headerText = "New chat message."
                    break
                case 'like':
                    headerText = ` and 1 other likes your ${activityVerb}.`
                    break
                case 'activityLike':
                    headerText = ` and 1 other likes your post.`
                    break
                case 'postReactionLike':
                    headerText = ` and 1 other likes your comment.`
                    break
                case 'repost':
                    headerText = ` and 1 other reposted your ${activityVerb}.`
                    break
                case 'follow':
                    headerText = ` and 1 other started following you.`
                    break
                case 'comment':
                    if (latestActivity.meta && latestActivity.meta['reaction_owner'] && parseInt(latestActivity.meta['reaction_owner']) == this.loggedUserId) {
                        headerText = ` and 1 other replied to your comment.`
                    } 
                    else if (latestActivity.meta['activity_owner_label']) {
                        headerText = ` and 1 other replied to a comment on ${latestActivity.meta['activity_owner_label']} post.`
                    } 
                    else {
                        headerText = ` and 1 other replied to a comment.`
                    }

                    break
                case 'reaction':
                    if (latestActivity.meta && latestActivity.meta['activity_owner'] && parseInt(latestActivity.meta['activity_owner']) == this.loggedUserId) {
                        headerText = ` and 1 other commented on your post.`
                    } 
                    else if (latestActivity.meta && latestActivity.meta['users'] && latestActivity.meta['users'].includes(this.loggedUserId)) {
                        headerText = ` and 1 other mentioned you in a post.`
                    } 
                    else if (latestActivity.meta['activity_owner_label']) {
                        headerText = ` and 1 other also commented on ${latestActivity.meta['activity_owner_label']} post.`
                    } 
                    else {
                        headerText = ` and 1 other commented on a post.`
                    }

                    break
                case 'post':
                    if (latestActivity.meta && latestActivity.meta['build_owners'] && latestActivity.meta['build_owners'].includes(this.loggedUserId)) {
                        headerText = ` and 1 other made a post on ${latestActivity?.meta?.build?.data?.handle}.`
                    } 
                    else if (latestActivity.meta && latestActivity.meta['garage_owner'] && parseInt(latestActivity.meta['garage_owner']) == this.loggedUserId) {
                        headerText = ` and 1 other made a post on your garage.`
                    } 
                    else if (latestActivity.meta && latestActivity.meta['event_owner'] && parseInt(latestActivity.meta['event_owner']) == this.loggedUserId) {
                        headerText = ` and 1 other made a post on your event.`
                    } 
                    else if (latestActivity.meta && latestActivity.meta['group_name']) {
                        headerText = ` and 1 other made a post in community ${latestActivity.meta['group_name']}.`
                    }
                    else {
                        headerText = ` and 1 other mentioned you in a post.`
                    }

                    break
                case 'group_post':
                    headerText = ` and 1 other made a post in community ${latestActivity.meta['group_name']}.`

                    break
                default:
                    
            }

            return headerText
        }
    },
    methods: {
        notifyClick() {
            setTimeout(() => {
                this.closeNotifications()
            }, 200)

            if (this.notification.verb == 'post' || this.notification.verb == 'reaction' || this.notification.verb == 'comment' || this.notification.verb == 'activityLike' || this.notification.verb == 'postReactionLike') {
                let thisActivity = {
                    id: this.latestActivity.target ? this.latestActivity.target : this.latestActivity.id,
                    actor: {
                        id: this.actor.id
                    }
                }

                if (this.latestActivity.meta && this.latestActivity.meta.reaction_id && !this.latestActivity.meta.comment_id) {
                    this.setStateData([
                        { scrollToReactionId: this.latestActivity.meta.reaction_id }
                    ])
                }

                if (this.latestActivity.meta && this.latestActivity.meta.comment_id) {
                    this.setStateData([
                        { scrollToCommentId: this.latestActivity.meta.comment_id }
                    ])
                }

                this.openActivityModal(thisActivity)
            }
            else if (this.notification.verb == 'follow' && this.actor) {
                this.routeTo(`/garage/${this.actor.handle}`)
            }
            // else if (this.notification.verb == 'completed_checklist' || this.notification.verb == 'complete_checklist') {
            //     this.openChecklist()
            // }
            // else if (this.notification.verb == 'build_traffic') {
            //     this.$inertia.get(this.route('analytics'))
            // }
            else if (this.notification.verb == 'event_invite') {
                this.routeTo(`/event/${this.latestActivity.meta.event_id}`)
            }
            // else if (this.notification.verb == 'group_invite') {
            //     this.$inertia.get(this.route('group.show', this.latestActivity.meta.group_id))
            // }
            // else if (this.notification.verb == 'group_request') {
            //     this.$inertia.get(this.route('group.show', this.latestActivity.meta.group_id), {
            //         pending: true
            //     })
            // }
            // else if (this.notification.verb == 'group_post') {
            //     let thisActivity = {
            //         id: this.latestActivity.target ? this.latestActivity.target : this.latestActivity.id,
            //         actor: {
            //             id: this.actor.id
            //         }
            //     }

            //     this.openActivityModal(thisActivity)
            // }
            else if (this.notification.verb == 'chat_invite') {
                this.routeTo(`/chat/${this.latestActivity.meta.chat_id}`)
            }
            else if (this.notification.verb == 'chat') {
                this.routeTo('/chats')
            }
            else if (this.notification.verb == 'collaboration_invite') {
                this.routeTo(`/build/${this.latestActivity.meta.build.data.handle}`)
            }
            // else if (this.notification.verb == 'group_approval') {
            //     this.$inertia.get(this.route('group.show', this.latestActivity.meta.group_id), {
            //         pending: true
            //     })
            // }
            else if (this.notification.verb == 'badge_awarded') {
                this.routeTo(`/build/${this.latestActivity.meta.build.data.handle}#trophies`)
            }
            else if (this.notification.verb == 'user_achievement' || this.notification.verb == 'user_badge' || this.notification.verb == 'hashtag_badge') {
                this.routeTo('/')
            }
        }
    }
}

</script>

