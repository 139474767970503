<template>
    <div v-if="mainActivity" class="p-1">
        <div v-if="isRepost" class="mb-2 text-xs italic"><span>Reposted by </span>
            <div class="hover:underline">
                {{ activity.actor.data.handle }}
            </div>
        </div>
        <div v-else-if="activity.meta && activity.meta.user_action && (!activity.meta.user_action_id || activity.meta.user_action_id != loggedUserId)" @click="clickUserAction()" class="mb-2 text-xs italic cursor-pointer">
            {{ activity.meta.user_action }}
        </div>
        <div class="flex items-start justify-between">
            <div class="flex items-center">
                <div @click="clickActor()" class="w-8 h-8 rounded-full border-2 border-orange-500 cursor-pointer" style="min-width: 2rem;">
                    <user-avatar :url="actor ? actor.profileImage : ''" :rounded="true" />
                </div>
                <div class="ml-3">
                    <div class="flex items-center text-sm">
                        <div @click="clickActor()" class="font-semibold hover:underline cursor-pointer">
                            {{ actor ? actor.name : 'Unknown' }}
                        </div>
                        <div v-if="isQuestion" class="ml-1">
                            asked a question
                        </div>
                        <div v-if="isSale" class="ml-1">
                             listed an item for sale
                        </div>
                    </div>
                    <p v-if="(true && meta) || (meta && meta.garage && meta.garage.data && meta.garage.data.handle)" class="text-sm">
                        <span v-if="meta.build && meta.build.data && meta.build.data.handle" class="flex flex-wrap items-center">
                            <span class="flex items-center">
                                <fa-icon icon="car" type="fas" class="h-4 w-4 mr-1" />
                                <router-link :to="`/build/${meta.build.data.handle}`" class="text-orange-400 break-all hover:underline">
                                    +{{ meta.build.data.handle }}
                                </router-link>
                            </span>
                        </span>
                        <span v-if="meta.event && meta.event.data && meta.event.data.id" class="flex flex-wrap items-center">
                            <span class="flex items-center">
                                <fa-icon icon="calendar-days" type="fas" class="h-4 w-4 mr-1" />
                                <div class="text-orange-400 break-all hover:underline">
                                    {{ meta.event.data.name }}
                                </div>
                            </span>
                        </span>
                        <span v-if="meta.mygroup && meta.mygroup.data && meta.mygroup.data.handle" class="flex flex-wrap items-center">
                            <span class="flex items-center">
                                <fa-icon icon="users" type="fas" class="h-4 w-4 mr-1" />
                                <div class="text-orange-400 break-all hover:underline">
                                    bz/{{ meta.mygroup.data.handle }}
                                </div>
                            </span>
                        </span>
                    </p>
                </div>
            </div>
            <div class="flex items-center mt-1 ml-2">
                <div v-if="loggedUser && !isMyPost && actor">
                    <follow-button :isFollowing="amIFollowing(actor.id)" :owner="actor" :queryId="queryId" :showText="true" :textOnly="true" buttonClasses="inline-flex mx-2" textClasses="text-base text-orange-500 font-semibold inline" :small="true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FollowButton from '@/shared/FollowButton'

export default {
    props: ['activity', 'modal', 'queryId', 'position'],
    components: {
        FollowButton
    },
    computed: {
        actor() {
            return this?.mainActivity?.meta?.original_actor?.data ? this.mainActivity.meta.original_actor.data : this.mainActivity.actor.data
        },
        fixedGroupName() {
            return this?.meta?.mygroup?.data?.name ? this.meta.mygroup.data.name.replace(/[^A-Za-z0-9]/g, '').toLowerCase() : null
        },
        isMyPost() {
            return this.loggedUserId == parseInt(this?.actor?.id)
        },
        isQuestion() {
            return this?.mainActivity?.meta?.question ?? false
        },
        isRepost() {
            return Boolean(this.activity?.object?.object)
        },
        isSale() {
            return this?.mainActivity?.meta?.sale ?? false
        },
        mainActivity() {
            return this.isRepost ? this.activity?.object : this.activity
        },
        meta() {
            return this.mainActivity?.meta || null 
        }
    },
    methods: {
        clickActor() {
            this.routeTo(`/garage/${this.actor.handle}`)
        },
        clickUserAction() {
            if ((this.activity?.meta?.reaction_id ?? false) && (!this.activity?.meta?.comment_id ?? false)) {
                this.setStateData([
                    { scrollToReactionId: this.activity.meta.reaction_id }
                ])
            }

            if (this.activity?.meta?.comment_id ?? false) {
                this.setStateData([
                    { scrollToCommentId: this.activity.meta.comment_id }
                ])
            }

            this.openActivityModal(this.activity, this.queryId, this.position)
        }
    }
}
</script>