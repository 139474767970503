import axios from 'axios'
import algoliasearch from 'algoliasearch/lite'
import anchorme from 'anchorme'
import { App as CapacitorApp } from '@capacitor/app'
import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { Device } from '@capacitor/device'
// import { NativeAudio } from '@capacitor-community/native-audio'
import { Network } from '@capacitor/network'
import OneSignal from 'onesignal-cordova-plugin'
import { SendIntent } from 'send-intent'

import {
    AppTrackingTransparency,
    AppTrackingStatusResponse
} from 'capacitor-plugin-app-tracking-transparency'

const appInitMixins = {
    computed: {
        apiUrl() {
            let myUrl = new URL(window.location.href)
            let myHost = myUrl.host

            if (!myHost.includes('localhost')) {
                return myHost.includes('apptest.buildz') ? 'https://staging.buildz.pro/' : (myHost.includes('dev.buildz') ? 'https://staging-dev.buildz.pro/' : 'https://buildz.pro/')
            }
            else {
                return `https://${this.localHostServer}`
            }
        }
    },
    watch: {
        isAppLaunchUrlReady: {
            handler(val) {
                if (val && !this.launchUrlInitFired) {
                    this.checkLaunchUrl()
                    this.initAppUrlOpen()
                    
                    this.setStateData([
                        { launchUrlInitFired: true }
                    ])
                }
            }
        },
        loggedUser: {
            immediate: true,
            handler(val) {
                if (val && !this.userFunctionsFired) {
                    this.initOneSignal()
                    this.checkOnboardingModals()
                    this.openUserSocket()
                    
                    this.setStateData([
                        { userFunctionsFired: true }
                    ])

                    this.getCart()
                }
            }
        }
    },
    methods: {
        appInit() {
            // this.initWask()
            var url = new URL(window.location.href)

            document.body.classList.add("overflow-y-scroll")

            this.initCapacitorUpdater()
            this.initDeviceInfo()

            if (this.isIOSApp) {
                this.appleATT()
            }

            if (this.isApp) {
                if (this.isAndroidApp) {
                    this.initBackButton()
                }
            }

            this.initAlgolia()
            this.setToken(localStorage.getItem('token'), true, true)
            this.initWhiteLabel()
            this.updateUTCTime()
            this.openPublicSocket()
            this.stripeInit()
            this.getCart()

            setInterval(() => {
                this.updateUTCTime()
                this.checkServerTime()
            }, 90000)

            window.addEventListener('scroll', this.handleBuildzScroll)
            window.addEventListener('resize', this.handleBuildzResize)
            window.addEventListener('keydown', this.handleBuildzKeydown)
            this.handleBuildzResize()
            this.handleBuildzScroll()

            document.addEventListener("wheel", function (event) {
                if (document.activeElement.type === "number"){
                    document.activeElement.blur()
                }
            })

            document.addEventListener("wheel", function (event) {
                if (document.activeElement.type === "number"){
                    document.activeElement.blur()
                }
            })
        },
        async appleATT() {
            const response = await AppTrackingTransparency.getStatus()

            if (response.status == 'notDetermined') {
                await AppTrackingTransparency.requestPermission()
            }
        },
        async checkLaunchUrl() {
            if (CapacitorApp ?? false) {
                try {
                    let launchUrl = await CapacitorApp.getLaunchUrl()

                    if (launchUrl?.url) {
                        this.processIncomingUrl(launchUrl.url)
                    }
                }
                catch (error) {
                }
            }
        },
        checkOnboardingModals() {
            if (!this.alreadyShownOnboardingModalsThisSession && !this.marketplaceSaleInProgress) {
                if (this.loggedUser && !this.hasLoggedIn) {
                    this.setStateData([
                        { showOnboardingSubCategoriesModal:  true }
                    ])

                    this.appsFlyerEvent(
                        {
                            eventName: 'New User',
                            eventValue: {
                            }
                        }
                    )
                }
            }
        },
        async getCachedFeed() {
            let myCachedFeed = await this.getStorage('cachedFeed')

            if (myCachedFeed?.value) {
                this.replaceStateDataArray([
                    { cachedHomeFeed: JSON.parse(myCachedFeed.value) }
                ])
            }
        },
        async getGlobalData() {
            let myStoredGlobal = await this.getStorage('global_data')
            
            if (myStoredGlobal?.value) {
                try {
                    this.setStateData([
                        { isGlobalData: true },
                        { countryCode: JSON.parse(myStoredGlobal.value).country_code ?? null },
                        { eventStatus: JSON.parse(myStoredGlobal.value).event_status ?? null },
                        { termsData: JSON.parse(myStoredGlobal.value).terms_data ?? [] },
                        { pageDataBuilds: JSON.parse(myStoredGlobal.value).build_page_data ?? {} },
                        { buildTypes: JSON.parse(myStoredGlobal.value).build_types },
                        { buildTemplates: JSON.parse(myStoredGlobal.value).build_templates },
                        { productGroupTiles: JSON.parse(myStoredGlobal.value).product_group_tiles },
                        { suggestions: JSON.parse(myStoredGlobal.value).suggestions },
                        { userTutorials: JSON.parse(myStoredGlobal.value).user_tutorials },
                        { vendorPaymentTypes: JSON.parse(myStoredGlobal.value).vendor_payment_types ?? [] },
                        { vendorPlans: JSON.parse(myStoredGlobal.value).vendor_plans ?? [] },
                        { homeSuggested: JSON.parse(myStoredGlobal.value).home_suggested ?? [] },
                        { groupOfferSuggested: JSON.parse(myStoredGlobal.value).group_offer_suggested ?? [] },
                        { productSuggested: JSON.parse(myStoredGlobal.value).product_suggested ?? [] },
                        { thankyouSuggested: JSON.parse(myStoredGlobal.value).thankyou_suggested ?? [] },
                        { communitiesModalData: JSON.parse(myStoredGlobal.value).onboarding_communities_modal_data ?? null },
                        { sellerCommissions: JSON.parse(myStoredGlobal.value).seller_commissions ?? [] },
                        { sellerWizardData: JSON.parse(myStoredGlobal.value).seller_wizard ?? [] }
                    ])
                }
                catch(error) {
                }
            }

            buildzAxios?.get(this.getRoute('globalData'))
                .then((response) => {
                    if (response.status == 200) {
                        this.setStateData([
                            { isGlobalData: true },
                            { isRealGlobalData: true },
                            { countryCode: this.objCopy(response?.data?.country_code) ?? null },
                            { eventStatus: this.objCopy(response?.data?.event_status) ?? null },
                            { termsData: this.objCopy(response?.data?.terms_data) ?? [] },
                            { pageDataBuilds: this.objCopy(response?.data?.build_page_data) ?? {} },
                            { buildTypes: this.objCopy(response?.data?.build_types) },
                            { buildTemplates: this.objCopy(response?.data?.build_templates) },
                            { productGroupTiles: this.objCopy(response?.data?.product_group_tiles) },
                            { suggestions: this.objCopy(response?.data?.suggestions) },
                            { userTutorials: this.objCopy(response?.data?.user_tutorials) },
                            { termsData: this.objCopy(response?.data?.terms_data) ?? [] },
                            { vendorPaymentTypes: response?.data?.vendor_payment_types },
                            { vendorPlans: response?.data?.vendor_plans },
                            { homeSuggested: response?.data?.home_suggested },
                            { groupOfferSuggested: response?.data?.group_offer_suggested },
                            { productSuggested: response?.data?.product_suggested },
                            { thankyouSuggested: response?.data?.thankyou_suggested },
                            { communitiesModalData: response?.data?.onboarding_communities_modal_data },
                            { sellerCommissions: response?.data?.seller_commissions },
                            { sellerWizardData: response?.data?.seller_wizard }
                        ])

                        this.setStorage('global_data', JSON.stringify(response.data))
                        this.checkServerTime()
                    }
                })
                .catch((err) => {
                    this.checkServerTime()
                })
        },
        async getUserData() {
            if (localStorage.getItem('token')) {
                let myStoredUser = await this.getStorage(`user_data_${localStorage.getItem('token')}`)

                if (myStoredUser?.value) {
                    try {
                        this.setStateData([
                            { loggedUser: JSON.parse(myStoredUser.value) ?? null }
                        ])
                    }
                    catch (error) {
                    }
                }
            }

            buildzAxios?.get(this.getRoute('userData'))
                .then((response) => {
                    if (response.status == 200 && response.data?.stream_token) {
                        this.setStateData([
                            { streamToken: response.data.stream_token },
                            { loggedUser: response.data.user ? this.objCopy(response.data.user) : null }
                        ])

                        this.setStorage(`user_data_${localStorage.getItem('token')}`, JSON.stringify(response.data.user))
                    }
                })
        },
        handleBuildzKeydown(e) {
            let keyCode = e.keyCode ? e.keyCode : e.which

            if (keyCode === 27 && this.popups.length) {
                this.setStateData([
                    { keyEscape: true }
                ])
            }
        },
        handleBuildzResize() {
            this.setStateData([
                { windowHeight: window.innerHeight },
                { windowWidth: window.innerWidth },
                { mainPartWidth: this.elGet('theMainPart') ? this.elGet('theMainPart').clientWidth : 0 }
            ])
        },
        handleBuildzScroll() {
            this.setStateData([
                { scrollTop: window.scrollY }
            ])
        },
        initAlgolia() {
            if (!this.algoliaSearchClient) {
                const myUrl = new URL(window.location.href)
                const myHost = myUrl.host

                let myAlgoliaAppId = null
                let myAlgoliaPublicKey = null

                if (!myHost.includes('localhost')) {
                    myAlgoliaAppId = myHost.includes('apptest.buildz') ? 'PVPJYTYOZ4' : (myHost.includes('dev.buildz') ? 'PVPJYTYOZ4' : 'KL228HWQFU')
                    myAlgoliaPublicKey = myHost.includes('apptest.buildz') ? '18a96fb710b7fffcd79edcd85afb165a' : (myHost.includes('dev.buildz') ? '18a96fb710b7fffcd79edcd85afb165a' : '2dfb201324d5d5992083848029344974')
                }
                else {
                    myAlgoliaAppId = this.localAlgoliaAppId
                    myAlgoliaPublicKey = this.localAlgoliaPublicKey
                }

                const algoliaClient = algoliasearch(
                    myAlgoliaAppId,
                    myAlgoliaPublicKey
                )

                if ((!myHost.includes('localhost') || this.localAlgoliaAppId == "KL228HWQFU") && !myHost.includes('apptest.buildz') && !myHost.includes('dev.buildz')) {
                    this.setStateData([
                        { isBackendLive: true }
                    ])
                }

                this.setStateData([
                    { algoliaSearchClient: algoliaClient }
                ])
            }
        },
        initAppStateChange() {
            if (CapacitorApp ?? false) {
                try {
                    CapacitorApp.addListener('appStateChange', (state) => {
                        this.readQueue()

                        // if (state.isActive) {
                        //     console.log('getLatest')
                        //     this.capgoGetLatest()
                        // }
                          
                        // if (!state.isActive) {
                        //     this.capgoInstallLatest()
                        // }
                    })
                }
                catch (error) {
                }
            }
        },
        initAppUrlOpen() {
            if (CapacitorApp ?? false) {
                try {
                    CapacitorApp.addListener('appUrlOpen', (event) => {
                        if (event.url.includes('?uid=') || event.url.includes('/activity/')) {
                            this.processIncomingUrl(event.url)
                        }
                        else {
                            const url = new URL(event.url)

                            if (anchorme.validate.url(url)) {
                                let relUrl = url.toString().substring(url.origin.length)
                                this.routeTo(relUrl)
                            }
                        }  
                    })
                }
                catch (error) {
                    
                }
            }
        },
        initBackButton() {
            if (CapacitorApp ?? false) {
                try {
                    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
                        if (this.popups.length) {
                            window.dispatchEvent(new KeyboardEvent('keydown', {
                                keyCode: 27
                            }))
                        }
                        else if (!canGoBack) {
                            //CapacitorApp.exitApp()
                        }
                        else {
                            window.history.back()
                        }
                    })
                }
                catch (error) {
                }
            }
        },
        initCapacitorUpdater() {
            if (CapacitorUpdater ?? false) {
                try {
                    CapacitorUpdater.notifyAppReady()
                }
                catch (error) {
                    console.log(error)
                }
            }
        },
        async initDeviceInfo() {
            const info = await Device.getInfo()

            this.setStateData([
                { deviceInfo: info }
            ])

            const devId = await Device.getId()

            this.setStateData([
                { deviceId: devId }
            ])

            if (info.platform != "web") {
                this.initNetwork()
                // this.initNfc()
                this.initSendIntent()
                // this.initSounds()
                this.initAppStateChange()
                // this.initCapacitorUpdater()
                // this.initAppsFlyer()
                this.getCachedFeed()
                this.setToken(localStorage.getItem('token'), true, true)
            }
            else {
                var url = new URL(window.location.href)

                if (url.searchParams.get("uid")) {
                    this.processIncomingUrl(window.location.href)
                }
            }

            setTimeout(() => {
                this.recordVisit()
            }, 5000)

            setTimeout(() => {
                this.readQueue()
            }, 5000)
        },
        async initNetwork() {
            if (Network ?? false) {
                try {
                    Network.addListener('networkStatusChange', status => {
                        this.setServerConnected(status.connected)
                    })
                }
                catch (error) {
                }

                const status = await Network.getStatus()
                this.setServerConnected(status.connected)
            }
        },
        initNfc() {
            // if (nfc ?? false) {
            //     try {
            //         nfc.addNdefListener((nfcEvent) => {
            //             let tag = nfcEvent.tag
            //             let ndefMessage = tag.ndefMessage
            //             let myUrl = "https://" + nfc.bytesToString(ndefMessage[0].payload).substring(1)
            //             this.processIncomingUrl(myUrl)
            //         })

            //         document.addEventListener("ndef-mime", (nfcEvent) => {
            //             let tag = nfcEvent.tag
            //             let ndefMessage = tag.ndefMessage
            //             let myUrl = "https://" + nfc.bytesToString(ndefMessage[0].payload).substring(1)
            //             this.processIncomingUrl(myUrl)
            //         })
            //     }
            //     catch (error) {
            //     }
            // }
        },
        initOneSignal() {
            if (OneSignal ?? false) {
                try {
                    OneSignal.setAppId(this.oneSignalAppId)

                    if (this.isIOSApp) {
                        OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
                            console.log("User accepted notifications: " + accepted)
                        })
                    }

                    OneSignal.setNotificationOpenedHandler((jsonData) => {
                        if (jsonData?.notification?.additionalData?.openUrl) {
                            let openUrl = jsonData.notification.additionalData.openUrl

                            if (openUrl.includes('?uid=') || openUrl.includes('/activity/')) {
                                this.processIncomingUrl(openUrl)
                            }
                            else {
                                const url = new URL(openUrl)

                                if (anchorme.validate.url(url)) {
                                    let relUrl = url.toString().substring(url.origin.length)
                                    this.routeTo(relUrl)
                                }
                            }
                        }
                    })

                    setTimeout(() => {
                        OneSignal.getDeviceState((result) => {
                            if (result.pushToken) {
                                // this.setStorage('push_token', result.pushToken)
                                this.setUninstallToken(result.pushToken)
                            }
                            
                            if (result.userId) {
                                buildzAxios?.post(this.getRoute('registerPlayerId'), {
                                    playerId: result.userId
                                })
                            }
                        })
                    }, 10000)
                }
                catch (error) {
                    
                }
            }
        },
        initSendIntent() {
            if (SendIntent) {
                try {
                    SendIntent.checkSendIntentReceived().then((result) => {
                        if (result) {
                            let myText = result.url ? result.url : (result.title ? result.title : '')
                            let myUrl = decodeURIComponent(myText)

                            if (anchorme.validate.url(myUrl)) {
                                this.setStateData([
                                    { shareIntentURL: myUrl },
                                    { showShareIntentModal: true },
                                    { shareIntentExternal: true }
                                ])
                            }
                        }
                    })
                    .catch((err) => {
                    })

                    window.addEventListener("sendIntentReceived", () => {
                        SendIntent.checkSendIntentReceived().then((result) => {
                            if (result) {
                                let myText = result.url ? result.url : (result.title ? result.title : '')
                                let myUrl = decodeURIComponent(myText)

                                if (anchorme.validate.url(myUrl)) {
                                    this.setStateData([
                                        { shareIntentURL: myUrl },
                                        { showShareIntentModal: true },
                                        { shareIntentExternal: true }
                                    ])
                                }
                            }
                        })
                        .catch((err) => {
                        })
                    })
                }
                catch (error) {
                }
            }
        },
        initSounds() {
            // if (NativeAudio && !this.soundsLoaded) {
            //     try {
            //         this.appSounds.forEach((sound) => {
            //             NativeAudio.preload({
            //                 assetId: sound.id,
            //                 assetPath: this.isAndroidApp ? `public/sound/${sound.file}` : `public/sound/${sound.file}`,
            //                 audioChannelNum: 1,
            //                 volume: 1.0,
            //                 isUrl: false
            //             })
            //         })

            //         this.setStateData([
            //             { soundsLoaded: true }
            //         ])
            //     }
            //     catch (error) {
            //     }
            // }
        },
        openPublicSocket() {
            if (Echo && !this.publicSocketOpen) {
                try {
                    Echo.channel('Buildz')
                        .listen('PushServerTime', (e) => {
                            this.updateServerTime(e.data)
                        })
                        .listen('PushNewSale', (e) => {
                            this.addLatestSale(e)
                        })
                        .listen('GroupOfferLivestream', (e) => {
                            console.log('e', e)
                            this.updateGroupBuyLivestream(e)
                        })

                    this.setStateData([
                        { publicSocketOpen: true }
                    ])
                }
                catch (error) {
                }
            }

            if (!this.publicSocketOpen) {
                setTimeout(() => {
                    this.openPublicSocket()
                }, 60000)
            }
        },
        openUserSocket() {
            if (Echo && this.loggedUser && !this.userSocketOpen) {
                try {
                    Echo.channel(`user.${this.loggedUserId}`)
                        .listen('VendorProductsPublished', (e) => {
                            if (e?.products) {
                                this.setStateData([
                                    { vendorProducts: e.products }
                                ])
                            }
                        })
                        .listen('VendorFileImported', (e) => {
                            if (e?.product_files) {
                                this.setStateData([
                                    { vendorProductFiles: e.product_files }
                                ])
                            }
                        })
                        .listen('PushSegmentCountResult', (e) => {
                            if (e?.segment_id) {
                                console.log(e)
                                this.pushSegmentCount(e)
                            }
                        })

                    this.setStateData([
                        { userSocketOpen: true }
                    ])
                }
                catch(error) {
                }

                if (this.loggedUser && !this.userSocketOpen) {
                    setTimeout(() => {
                        this.openUserSocket()
                    }, 60000)
                }
            }
        },
        processIncomingUrl(inUrl) {
            let url = new URL(inUrl)

            if (url.pathname == "/" && url.searchParams.get("uid")) {
                this.checkSmartDeviceCode(url.searchParams.get("uid"))
            }
            else if (url.pathname.includes("/activity/")) {
                let pathnameAr = url.pathname.split('/')

                if (pathnameAr.length == 3) {
                    this.getActivityFromData(pathnameAr[2])
                }
            }
        },
        async setToken(inToken, inGlobalData = true, inUserData = false) {
            buildzAxios = axios.create({
                baseURL: this.apiUrl,
                headers: { Accept: 'application/json' }
            })

            buildzAxios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

            if (inToken) {
                buildzAxios.defaults.headers.common = {
                    'Authorization': "Bearer " + inToken,
                    'Content-Type': 'multipart/form-data'
                }
            }
            else {
                buildzAxios.defaults.headers.common = {
                    'Content-Type': 'multipart/form-data'
                }
            }

            if (inGlobalData) {
                this.getGlobalData()
            }

            if (inUserData) {
                let myUser = await this.getUserData()
                this.getSellerVendorData()
            }
        }
    }
}

export default appInitMixins
