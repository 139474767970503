<template>
    <div>
        <div class="relative bg-black">
            <img v-if="!videoSources[0].includes('cloudflarestream')" @load="$emit('setChatHeight')" v-show="!loaded" :src="poster" class="feed-gallery-preview mx-auto"/>    
            <div v-if="!videoSources[0].includes('cloudflarestream')" class="w-full">
                <video v-show="loaded" :poster="poster"
                    ref="video"
                    v-on:loadeddata="loaded = true"
                    v-on:play="playing = true"
                    v-on:pause="playing = false"
                    v-bind="myAttrs"
                    class="mx-auto"
                >
                    <source v-for="(source, i) in videoSources"
                        :src="source" :key="i"
                    />
                </video>
            </div>
            <div v-else class="w-full">
                <div style="position: relative; padding-top: 56.25%">
                    <iframe ref="video"
                        :src="fixSrc()"
                        style="border: none; position: absolute; top: 0; height: 100%; width: 100%"
                        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                        allowfullscreen="true"
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isIntersecting: {
            default: false,
            required: false,
            type: Boolean
        },
        poster: String,
        src: {
            type: String,
            required: false
        },
        sources: {
            type: Array,
            required: false
        },
        attrs: {
            type: Object,
            required: false,
            default: function() {
                return {
                    controls: true,
                    playsinline: true,
                    loop: false,
                    autoplay: false,
                    muted: false
                }
            }
        },
        shouldPauseOnExit: {
            default: true,
            required: false,
            type: Boolean
        },
        modal: {
            default: false,
            required: false,
            type: Boolean
        },
        noAutoPlay: {
            default: false,
            required: false,
            type: Boolean
        }
    },
    data() {
        return {
            hasBeenClicked: false,
            loaded: false,
            playing: false,
            posterLoaded: false,
            wasPlaying: false
        }
    },
    computed: {
        isCloudFlare() {
            return this.videoSources[0].includes('cloudflarestream')
        },
        myAttrs() {
            return {
                controls: this.attrs.controls || this.hasBeenClicked,
                playsinline: this.attrs.playsinline,
                loop: this.attrs.loop,
                autoplay: this.attrs.autoplay,
                muted: this.attrs.muted
            }
        },
        videoSources() {
            return this.sources || [this.src]
        }
    },
    watch: {
        loaded(isLoaded) {
            if (isLoaded) {
                this.$emit('loaded')
                this.$emit('setChatHeight')
            }
        },
        isIntersecting: {
            handler(val) {
                if (val && this.isCloudFlare) {
                    this.$refs.video.click()
                }
                else {
                    if (val && !this.loaded) {
                        this.$refs.video.load()
                    }
                    else if (val && !this.noAutoPlay && this.shouldPauseOnExit && this?.$refs?.video && !this.playing) {
                        this.$refs.video.click()
                    }
                    else if (!val && !this.noAutoPlay && this.shouldPauseOnExit && this?.$refs?.video && this.playing) {
                        this.$refs.video.click()
                    }
                }
            }
        }
    },
    mounted() {
        this.$refs.video.addEventListener('click', this.onClick)
        this.$refs.video.addEventListener('volumechange', this.onVolume)

        if (this.isIOSDevice) {
            this.loaded = true
        }

        this.$refs.video.click()
    },
    beforeUnmount() {
        this.$refs.video.removeEventListener('click', this.onClick)
        this.$refs.video.removeEventListener('volumechange', this.onVolume)
    },
    methods: {
        clickMe() {
            this.$emit('clickMe')
        },
        fixSrc() {
            return this.videoSources[0].replace('/watch', '/iframe')
        },
        getPoster() {
            if (this.isIntersecting && !this.posterLoaded) {
                this.posterLoaded = true
                return this.poster
            } else if (this.posterLoaded) {
                return this.poster
            } else {
                return ''
            }
        },
        onClick(e) {
            if (e.x > 0 || e.y > 0) {
                this.hasBeenClicked = true
            }
            else if (this.shouldPauseOnExit && !this.isCloudFlare) {
                if (this.playing) {
                    this.$refs.video.pause()
                }
                else {
                    this.$refs.video.play()
                }
            }
        },
        onVolume(e) {
            this.$emit('clickMute', this.$refs.video.muted)
        }
    }
}
</script>