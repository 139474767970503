export default {
	methods: {
		transformUrl(url, options, vidWidth = 0, vidHeight = 0) {
            if (!(typeof url === 'string' || url instanceof String)) return '';

            if (url.includes('imagedelivery.net')) {
                let transformString = ''
                
                options?.forEach((option)=>{
                    if (option == 'bw') transformString += 'blur=20,'
                    if (option == 'xs') transformString += 'w=40,'
                    if(option == 'sm') transformString += 'w=100,'
                    if(option == 'md') transformString += 'w=400,'
                    if(option == 'lg') transformString += 'w=1280,'
                    if(option == 'xl') transformString += 'w=1920,'
                })
                
                if (transformString) {
                    transformString = transformString.slice(0, -1)
                }

                let newUrl = url.replace('/public', '/') + transformString

			    return newUrl
            }

            if (url.includes('cloudflarestream')) {
                return url
            }

            if (!url.includes('res.cloudinary')) {
                return url
            }

            if (url.includes('graph.facebook.com') && url.includes('type=normal')) {
                return url.replace('type=normal', 'type=large')
            }

            let urlNoVersion = url.replace(/\/v[0-9].*?\//gm,'/');

            let prefixRegex = new RegExp('(.*\/)(?:' + this.cloudinaryFolder + ')', 'gi');
            let prefixMatch = prefixRegex.exec(urlNoVersion);
            let prefix = '';
            if(prefixMatch) prefix = prefixMatch[1]; else return urlNoVersion;

            let suffixRegex = new RegExp('\/(' + this.cloudinaryFolder + '\/[^.]*)', 'gi');
            let suffixMatch = suffixRegex.exec(urlNoVersion);
            let suffix = '';
            if(suffixMatch) suffix = suffixMatch[1]; else return urlNoVersion;

            
            let transformString = '';

            // Video is the most expensive transformation therefore we need 
            // to ensure we only call a single transformation
            if(options?.includes('video')){ 
                suffix += '.mp4';
                transformString += 'b_blurred:400:15,c_pad,w_400,h_500/';
            }
            else{
                options?.forEach((option)=>{
                    if(option == 'optimize') transformString += 'f_auto,dpr_auto/';
                    if (option == 'q80') transformString += 'q_80/';
                    if (option == 'bw') transformString += 'e_grayscale/';
                    if(option == 'crop') transformString += 'c_crop,g_custom/';
                    if (option == 'xs') transformString += 'c_limit,w_40/';
                    if(option == 'sm') transformString += 'c_limit,w_100/';
                    if(option == 'md') transformString += 'c_limit,w_400/';
                    if(option == 'lg') transformString += 'c_limit,w_1280/';
                    if(option == 'xl') transformString += 'c_limit,w_1920/';
                });
            }
            

            let newUrl = prefix + transformString + suffix;

			return newUrl;
		},
        posterUrl(url, options, vidWidth = 0, vidHeight = 0) {
            if (!(typeof url === 'string' || url instanceof String)) return '';

            let urlNoVersion = url.replace(/\/v[0-9].*?\//gm, '/');

            let prefixRegex = new RegExp('(.*\/)(?:' + this.cloudinaryFolder + ')', 'gi');
            let prefixMatch = prefixRegex.exec(urlNoVersion);
            let prefix = '';
            if (prefixMatch) prefix = prefixMatch[1]; else return urlNoVersion;

            let suffixRegex = new RegExp('\/(' + this.cloudinaryFolder + '\/[^.]*)', 'gi');
            let suffixMatch = suffixRegex.exec(urlNoVersion);
            let suffix = '';
            if (suffixMatch) suffix = suffixMatch[1]; else return urlNoVersion;

            let transformString = '';

            // let containerWidth = null

            // if (containerId) {
            //     let myContainer = this.getRect(containerId)
            //     containerWidth = Math.round(myContainer.width)
            // }

            options?.forEach((option) => {
                if (option == 'optimize') transformString += 'f_auto,dpr_auto/';
                if (option == 'q80') transformString += 'q_80/';
                if (option == 'crop') transformString += 'c_crop,g_custom/';
                if (option == 'xs') transformString += 'c_limit,w_40/';
                if (option == 'sm') transformString += 'c_limit,w_100/';
                if (option == 'md') transformString += 'c_limit,w_400/';
                if (option == 'lg') transformString += 'c_limit,w_1280/';
                if (option == 'xl') transformString += 'c_limit,w_1920/';
                //if (option == 'video-md') transformString += containerId && containerWidth ? `b_black,c_pad,w_${containerWidth},h_500/` : 'b_black,c_pad,w_400,h_500/';

                if (option == 'video-md') {
                    // if (vidWidth > 0 && vidHeight > 0) {
                    //     transformString += `b_black,c_pad,w_${vidWidth},h_${vidHeight}/`;
                    // }
                    // else if (vidWidth > 0) {
                    //     transformString += `b_black,c_pad,w_${vidWidth},h_500/`;
                    // }
                    // else {
                    //     transformString += 'b_black,c_pad,w_400,h_500/';
                    // }
                    transformString += 'b_black,c_pad,w_400,h_500/';
                }

                if (option == 'video-chat') transformString += 'b_black,c_pad,w_200,h_300/';
            });

            let newUrl = prefix + transformString + suffix + '.jpg';

            return newUrl;
        }
	}
}